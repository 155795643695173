import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../plugins/Spinner";
import Header from "../layout/Header";
import Swal from "sweetalert2";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Navigation from "../layout/Navigation";
import logoFooter from "../../imgs/logo-footer.png";
import useAPI from "../../hooks/useAPI";
import dayjs from "dayjs";
import Modal from "../layout/Modal";
import { FaAngleLeft, FaHistory, FaHourglassHalf, FaLockOpen } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { BiSolidHelpCircle } from "react-icons/bi";

const Support = () => {
  const listDepartment = [
    {
      id: 1,
      content: "Información general",
    },
    {
      id: 2,
      content: "Dudas o inquietudes",
    },
    {
      id: 3,
      content: "Configuraciones generales",
    },
    {
      id: 4,
      content: "Reporte de fallas o errores",
    },
    {
      id: 5,
      content: "Asistencia técnica",
    },
  ];

  const listPriority = [
    {
      id: 1,
      value: "Baja",
    },
    {
      id: 2,
      value: "Media",
    },
    {
      id: 3,
      value: "Alta",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);

  const [mode, setMode] = useState("list");

  const [ticketList, setTicketList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [navegationView, setNavegationView] = useState("open");
  const [filtOpen, setFiltOpen] = useState([]);
  const [filtHoping, setFiltHoping] = useState([]);
  const [filtHistory, setFiltHistory] = useState([]);

  const [priorityList, setPriorityList] = useState([]);

  const [consecutiveId, setConsecutiveId] = useState("");
  const [idTicket, setIdTicket] = useState("");
  const [storeTicket, setStoreTicket] = useState("");
  const [statusTicket, setStatusTicket] = useState("");

  const [arrayQuestion, setArrayQuestion] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [mediaSelected, setMediaSelected] = useState("");
  const [media, setMedia] = useState([]);

  const [prioritySelected, setPrioritySelected] = useState("");

  const [showInfo, setShowInfo] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const [showInfo3, setShowInfo3] = useState(false);

  const [imageSelected1, setImageSelected1] = useState("");
  const [imageSelected2, setImageSelected2] = useState("");
  const [imageSelected3, setImageSelected3] = useState("");

  const { urlAPI_1, urlAPI_2, urlAPI_3, name_version_app, number_version_app, fetchWithAuth } = useAPI();
  const token = localStorage.getItem("USER_TOKEN");

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {department: "",affair: "",question: "",},});

  useEffect(() => {
    getTickets();

    setDepartmentList(listDepartment);
    setPriorityList(listPriority);
  }, []);

  // Listar tickets
  const getTickets = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "allTickets";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA tickets ==========", result);

      setLoading(false);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
        setTicketList([]);
      } else {
        // setTicketList(result.data);
        setFiltOpen(result.data);

        const resultFilter = result.data.filter((element) => {
          if (
            element.status === "Abierto" &&
            element.priority === "Sin asignar"
          ) {
            return element;
          }
        });
        console.log(">>>>>>>>>>>>>>>", resultFilter);

        if (resultFilter.length === 0) {
          setValidation(true);
          console.log("No hay abiertos....");
        } else {
          console.log("si hay...............");
          setValidation(false);
          setTicketList(resultFilter);
          setNavegationView("open");
        }

        setFiltHoping(result.data);
        setFiltHistory(result.data);
        // setValidation(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //seleccionar un ticket
  const onViewTicket = (id) => {
    setMode("create");
    const ticket = ticketList.filter((ticket) => {
      if (ticket._id === id) {
        return ticket;
      }
    });
    const ticketSelected = ticket[0];
    const arrayQuestions = ticket[0].question;
    const questions = ticket[0].question[0].content;
    const arrayMedia = ticket[0].question[0].media;
    console.log("arrayQuestions=>", arrayQuestions);
    console.log("questions=>", questions);
    console.log("Ticket seleccionado=>", ticketSelected);
    if (ticketSelected.priority != "Sin asignar") {
      setPrioritySelected(ticketSelected.priority);
    }

    reset({
      department: ticketSelected.department,
      affair: ticketSelected.affair,
      question: questions,
      // priority: pri,
    });

    setMedia(arrayMedia);

    setArrayQuestion(arrayQuestions);
    setIdTicket(ticketSelected._id);
    setConsecutiveId(ticketSelected.consecutiveId);
    setStoreTicket(ticketSelected.user);
    setStatusTicket(ticketSelected.status);

    const arrayFinal = arrayQuestions;

    var arrayNew = [];
    for (let i = 1; i < arrayFinal.length; i++) {
      const element = arrayFinal[i];
      // console.log("elemento......", element);
      arrayNew.push(element);
    }
    console.log("arrayNew......", arrayNew);
    setFinalArray(arrayNew);
  };

  // Enviar nueva observación
  var newArray = arrayQuestion;
  const putTicket = async () => {
    console.log("Array question", newArray);

    const { department, affair, question, observations } = watch();

    const today = new Date();

    if (observations) {
      if (imageSelected1 || imageSelected2 || imageSelected3) {
        newArray.push({
          content: observations,
          media: {
            image: imageSelected1,
            image2: imageSelected2,
            image3: imageSelected3,
          },
          created: today,
          email: "info@sendingup.co",
        });
      } else {
        newArray.push({
          content: observations,
          created: today,
          email: "info@sendingup.co",
        });
      }
    }

    console.log("Array 2", newArray);

    const newObject = {
      department,
      affair,
      question: newArray,
    };
    console.log("newObject", newObject);
    console.log("idTicket-idTicket-idTicket-idTicket-idTicket", idTicket);
    try {
      setLoading(true);
      const url = urlAPI_3 + `tickets/${idTicket}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(newObject)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data Updated ticket", result);

      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setMode("list");
      // navigate("/home/support");
    } catch (error) {
      console.log(error);
    }
  };

  // Actualizar prioridad del ticket
  const putPriority = async () => {
    console.log("prioridad seleccionada", prioritySelected);
    var object = {
      priority: prioritySelected,
    };

    try {
      setLoading(true);
      const url = urlAPI_3 + `ticketPriority/${idTicket}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(object)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("prioridad actualizada........", result);

      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Cerrar ticket
  const putClose = async () => {
    console.log("Cerrando el ticket");
    var date = new Date();
    var object = {
      status: "Solucionado",
      solutionDate: date,
    };

    try {
      setLoading(true);
      const url = urlAPI_3 + `closeTicket/${idTicket}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(object)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Ticket cerrado........", result);

      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setMode("list");
      getTickets();
      setNavegationView("history");
    } catch (error) {
      console.log(error);
    }
  };

  // FILTRAR ABIERTOS
  const onOpen = () => {
    const resultFilter = filtOpen.filter((element) => {
      if (element.status === "Abierto" && element.priority === "Sin asignar") {
        return element;
      }
    });
    // console.log("resultFilter", resultFilter);

    if (resultFilter.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }

    setTicketList(resultFilter);
    setNavegationView("open");
  };

  // FILTRAR ESPERANDO SOLUCION
  const onHoping = () => {
    const resultFilter1 = filtHoping.filter((element) => {
      if (element.status === "Abierto" && element.priority != "Sin asignar") {
        return element;
      }
    });
    // console.log("resultFilter1", resultFilter1);

    if (resultFilter1.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }

    setTicketList(resultFilter1);
    setNavegationView("closed");
  };

  // FILTRAR POR HISTORIA
  const onHistory = async () => {
    const resultFilter2 = filtHistory.filter((element) => {
      if (
        element.status === "Solucionado" &&
        element.priority != "Sin asignar"
      ) {
        return element;
      }
    });
    // console.log("resultFilter2", resultFilter2);

    if (resultFilter2.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
    setTicketList(resultFilter2);
    setNavegationView("history");
  };

  //Convertit imagen 1 en base64
  const onImage1 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage1Base64(file);
    console.log("Imagen 1========> ", base64);
    setImageSelected1(base64);
  };
  const convertImage1Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 2 en base64
  const onImage2 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage2Base64(file);
    console.log("Imagen 2========> ", base64);
    setImageSelected2(base64);
  };
  const convertImage2Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 3 en base64
  const onImage3 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage3Base64(file);
    console.log("Imagen 3========> ", base64);
    setImageSelected3(base64);
  };
  const convertImage3Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
  const user = localStorage.getItem("USER_NAME");

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {/* <Navigation user={user} roleDescription={roleDescription} /> */}
      {/* <div
        style={{
          marginLeft: "119px",
          marginRight: "38px",
          marginTop: "27px",
        }}
      > */}
        {mode === "list" && (
          <div className="px-3">
            <div className="col-xs-9 heading">
              <div>
                <h1>Solicitudes</h1>
                {/* <input
                  type="button"
                  className="btn btn-default"
                  value="Crear ticket"
                  onClick={() => {
                    setMode("create");
                  }}
                /> */}
              </div>
              <Header />
            </div>

            <header>
              <input type="hidden" id="idStore" size="40" value="1399" name="store"/>
              <a className={`btn-filter btn btn-link ${navegationView === "open" ? "active" : null}`} onClick={onOpen}>
                <FaLockOpen className="icon-lock-open mr-0" style={{ fontSize: "24px" }} />{" "}
                Abiertos
              </a>
              <a className={`btn-filter btn btn-link ${navegationView === "closed" ? "active" : null}`} onClick={onHoping}>
                <FaHourglassHalf className="icon-hourglass-2 mr-0" style={{ fontSize: "21px" }}/>{" "}
                Esperando solución
              </a>
              <a className={`btn-filter btn btn-link ${navegationView === "history" ? "active" : null}`}onClick={onHistory}>
                <FaHistory className="icon-history mr-0" style={{ fontSize: "22px" }} />{" "}
                Historial
              </a>
            </header>

            <div
              className="container-fluid mt-3 px-0"
              style={{ height: "580px", overflowY: "auto" }}
            >
              <table className="table table-striped">
                <thead>
                  <tr key="">
                    <th># Ticket</th>
                    <th>Prioridad</th>
                    <th>Estado</th>
                    <th>Agente - Compañia</th>
                    <th>Departamento</th>
                    <th>Asunto</th>
                    <th>Fecha de solicitud</th>
                    {/* <th>Fecha de solicitud</th>
                    <th>Hora de solicitud</th> */}
                    {navegationView === "history" ? (
                      <>
                        {/* <th>Fecha de respuesta</th> */}
                        <th>Fecha de solución</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody style={{ width: "1170px" }}>
                  {ticketList &&
                    ticketList.map((ticket, t) => (
                      <tr key={t}>
                        <td onClick={() => onViewTicket(ticket._id)}>
                          {ticket.consecutiveId}
                        </td>
                        <td>{ticket.priority}</td>
                        <td>{ticket.status}</td>
                        <td>
                          {ticket.user.length >= 10
                            ? ticket.user.substr(0, 10) + "..."
                            : ticket.user}{" "}
                          - {ticket.nameCompany}
                        </td>
                        <td>
                          {ticket.department.length >= 19
                            ? ticket.department.substr(0, 19) + "..."
                            : ticket.department}
                        </td>
                        <td>
                          {ticket.affair.length >= 22
                            ? ticket.affair.substr(0, 22) + "..."
                            : ticket.affair}
                        </td>
                        <td>
                          {dayjs(ticket.created)
                            .locale("es")
                            .format("DD MMM YYYY, hh:mm")}
                        </td>
                        {/* <td>
                          {dayjs(ticket.created)
                            .locale("es")
                            .format("hh:mm:ss")}
                        </td> */}
                        {navegationView === "history" ? (
                          <>
                            {/* <td>
                              {ticket.requestDate} {ticket.requestTime}
                            </td> */}
                            <td>
                              {dayjs(ticket.solutionDate)
                                .locale("es")
                                .format("DD MMM YYYY, hh:mm")}
                            </td>
                          </>
                        ) : null}
                      </tr>
                    ))}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    
                    {navegationView === "open"
                      ? <FaLockOpen style={{ fontSize: "55px" }}/>
                      : navegationView === "closed"
                      ? <FaHourglassHalf style={{ fontSize: "55px" }}/>
                      : navegationView === "history"
                      ? <FaHistory style={{ fontSize: "55px" }}/>
                      : null
                    }
                    <h1 style={{ fontSize: "26px" }}>
                      {navegationView === "open"
                        ? "En el momento no tienes tickets abiertos"
                        : navegationView === "closed"
                        ? "En el momento no tienes tickets en espera de solución"
                        : navegationView === "history"
                        ? "En el momento no tienes tickets en historial"
                        : null}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {mode === "create" && (
          <div className="px-3">
            <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
              <div style={{ height: "45px", lineHeight: "95px" }}>
                <button
                  type="button"
                  className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
                  onClick={() => {
                    setMode("list");
                  }}
                >
                  <FaAngleLeft /> Solicitudes
                </button>
              </div>
              <Header />
            </div>

            <section
              className="module-create-user"
              style={{ width: "90%", marginBottom: "40px" }}
            >
              <header className="message pb-2">
                <div id="tittleFormProduct">
                  <h1>
                    Detalles del ticket{" "}
                    <strong>{`${consecutiveId} - ${storeTicket}`}</strong>
                  </h1>
                  <h4 className="my-3" style={{ fontSize: "18px" }}>
                    Estado: <strong>{statusTicket}</strong>
                  </h4>
                  <button
                    id="closeSupport-button"
                    className="btn btn-success btn-sm"
                    supportid="132"
                    style={{ fontSize: "12px" }}
                    onClick={() => putClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </header>

              <form
              // onSubmit={handleSubmit(putCompany)}
              >
                <div className="module-section" id="product-basic">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label htmlFor="select">Departamento: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                name="affair"
                                id="affair"
                                aria-readonly
                                type="text"
                                disabled
                                {...register("department", {
                                  required: {
                                    value: true,
                                    message: "El departamento es obligatoria.",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Asunto: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                name="affair"
                                id="affair"
                                aria-readonly
                                type="text"
                                disabled
                                {...register("affair", {
                                  required: {
                                    value: true,
                                    message: "El numero asunto es obligatorio.",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                            <label htmlFor="select">Prioridad: </label>
                            <select
                              name="priority"
                              id="priority"
                              className="form-control"
                              disabled={
                                statusTicket != "Solucionado" ? false : true
                              }
                              defaultValue={prioritySelected}
                              // {...register("priority")}
                              onChange={(e) =>
                                setPrioritySelected(e.target.value)
                              }
                            >
                              <option value="" key="0" disabled selected>
                                Seleccione...
                              </option>
                              {priorityList &&
                                priorityList.map((dep, d) => (
                                  <option value={dep.value} key={d}>
                                    {dep.value}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Pregunta: </label>
                            <div className="su-control-group">
                              <textarea
                                className="form-control"
                                name="question"
                                id="question"
                                aria-readonly
                                style={{ height: "120px" }}
                                disabled
                                {...register("question", {
                                  required: {
                                    value: true,
                                    message: "La pregunta es obligatorio.",
                                  },
                                })}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      {media && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-product-gallery d-flex">
                              {media.image && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(media.image);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img src={media.image} className="w-100" />
                                  </div>
                                </div>
                              )}

                              {media.image2 && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(media.image2);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img src={media.image2} className="w-100" />
                                  </div>
                                </div>
                              )}

                              {media.image3 && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(media.image3);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img src={media.image3} className="w-100" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <footer className="mx-0">
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 right">
                      <input
                        className="btn btn-lg btn-info"
                        value="Actualizar prioridad"
                        style={
                          statusTicket != "Solucionado"
                            ? { cursor: "pointer" }
                            : { cursor: "not-allowed" }
                        }
                        // type="submit"
                        type="button"
                        disabled={statusTicket != "Solucionado" ? false : true}
                        onClick={() => putPriority()}
                      />
                    </div>
                  </div>
                </footer>
              </form>
            </section>

            {finalArray &&
              finalArray.map((question, q) => (
                <section
                  key={q}
                  className="module-create-user"
                  style={{ width: "90%", marginBottom: "40px" }}
                >
                  <header className="message pb-2">
                    <div id="tittleFormProduct">
                      <h5 style={{ fontSize: "14px" }}>
                        {dayjs(question.created)
                          .locale("es")
                          .format("DD MMMM YYYY, hh:mm a")}
                      </h5>
                      <p>
                        <strong>{question.email}: </strong>
                        {question.content}
                      </p>

                      {question.media && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-product-gallery d-flex">
                              {question.media.image && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(question.media.image);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img
                                      src={question.media.image}
                                      className="w-100"
                                    />
                                  </div>
                                </div>
                              )}

                              {question.media.image2 && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(question.media.image2);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img
                                      src={question.media.image2}
                                      className="w-100"
                                    />
                                  </div>
                                </div>
                              )}

                              {question.media.image3 && (
                                <div
                                  className="su-control su-widget-image small uncut image_url_supp mr-1 active"
                                  id="image2_url"
                                  onClick={() => {
                                    setModal(true);
                                    setMediaSelected(question.media.image3);
                                  }}
                                >
                                  <div
                                    className="rectangle-box"
                                    id="boximg-img2"
                                  >
                                    <div className="rectangle-content">
                                      <div></div>
                                    </div>
                                    <img
                                      src={question.media.image3}
                                      className="w-100"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </header>
                </section>
              ))}

            {statusTicket != "Solucionado" ? (
              <section className="module-create-user" style={{ width: "90%" }}>
                <header className="message">
                  <div id="tittleFormProduct">
                    <h1>Responder</h1>
                  </div>
                </header>

                <form onSubmit={handleSubmit(putTicket)}>
                  <div className="module-section" id="product-basic">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label htmlFor="select">
                                Respuestas cargadas:{" "}
                              </label>
                              <select
                                // name="department"
                                id="department"
                                className="form-control"
                                // {...register("department", {
                                //   required: {
                                //     value: true,
                                //     message: "El departamento es obligatoria.",
                                //   },
                                // })}
                              >
                                <option value="" key="0" disabled selected>
                                  Seleccione...
                                </option>
                                {departmentList &&
                                  departmentList.map((dep, d) => (
                                    <option value={dep.id} key={d}>
                                      {dep.content}
                                    </option>
                                  ))}
                              </select>
                              {errors.department && (
                                <Alert>* {errors.department.message}</Alert>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                              <label>Observaciones: </label>
                              <div className="su-control-group">
                                <textarea
                                  className="form-control"
                                  name="question"
                                  id="question"
                                  aria-readonly
                                  style={{ height: "120px" }}
                                  {...register("observations", {
                                    required: {
                                      value: true,
                                      message: "La pregunta es obligatorio.",
                                    },
                                  })}
                                ></textarea>
                                {errors.question && (
                                  <Alert>* {errors.question.message}</Alert>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-control">
                              <label>Imagenes adjuntas: </label>{" "}
                              <small>adjunta máximo 3 imagenes</small>
                            </div>
                            <div className="su-product-gallery d-flex">
                              <div
                                className={
                                  imageSelected1
                                    ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                    : "su-control su-widget-image small uncut image_url_supp mr-1"
                                }
                                id="image2_url"
                                data-key="image2_url"
                              >
                                <label htmlFor="image1">
                                  <span>Imagen</span>
                                </label>
                                {imageSelected1 && (
                                  <RiCloseCircleFill
                                    className="close icon-cancel-circled mr-1"
                                    onClick={() => setImageSelected1("")}
                                    style={{
                                      position: "absolute",
                                      zIndex: "99999",
                                      right: "0",
                                      fontSize: "27px",
                                    }}
                                  />
                                )}
                                <input
                                  type="file"
                                  id="image1"
                                  name="image1"
                                  accept="image/gif,image/jpeg,image/jpg,image/png"
                                  onChange={(e) => onImage1(e)}
                                />

                                <div
                                  className="rectangle-box"
                                  id="boximg-img2"
                                  htmlFor={imageSelected1 ? "image1" : "none"}
                                >
                                  <div className="rectangle-content">
                                    <div></div>
                                  </div>
                                  {imageSelected1 && (
                                    <img
                                      src={imageSelected1}
                                      className="w-100"
                                    />
                                  )}
                                </div>
                              </div>

                              <div
                                className={
                                  imageSelected2
                                    ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                    : "su-control su-widget-image small uncut image_url_supp mr-1"
                                }
                                id="image2_url"
                                data-key="image2_url"
                              >
                                <label htmlFor="image2">
                                  <span>Imagen</span>
                                </label>
                                {imageSelected2 && (
                                  <RiCloseCircleFill
                                    className="close icon-cancel-circled mr-1"
                                    onClick={() => setImageSelected2("")}
                                    style={{
                                      position: "absolute",
                                      zIndex: "99999",
                                      right: "0",
                                      fontSize: "27px",
                                    }}
                                  />
                                )}
                                <input
                                  type="file"
                                  id="image2"
                                  name="image2"
                                  accept="image/gif,image/jpeg,image/jpg,image/png"
                                  onChange={(e) => onImage2(e)}
                                />

                                <div
                                  className="rectangle-box"
                                  id="boximg-img2"
                                  htmlFor={imageSelected2 ? "image2" : "none"}
                                >
                                  <div className="rectangle-content">
                                    <div></div>
                                  </div>
                                  {imageSelected2 && (
                                    <img
                                      src={imageSelected2}
                                      className="w-100"
                                    />
                                  )}
                                </div>
                              </div>

                              <div
                                className={
                                  imageSelected3
                                    ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                    : "su-control su-widget-image small uncut image_url_supp mr-1"
                                }
                                id="image2_url"
                                data-key="image2_url"
                              >
                                <label htmlFor="image3">
                                  <span>Imagen</span>
                                </label>
                                {imageSelected3 && (
                                  <RiCloseCircleFill
                                    className="close icon-cancel-circled mr-1"
                                    onClick={() => setImageSelected3("")}
                                    style={{
                                      position: "absolute",
                                      zIndex: "99999",
                                      right: "0",
                                      fontSize: "27px",
                                    }}
                                  />
                                )}
                                <input
                                  type="file"
                                  id="image3"
                                  name="image3"
                                  accept="image/gif,image/jpeg,image/jpg,image/png"
                                  onChange={(e) => onImage3(e)}
                                />

                                <div
                                  className="rectangle-box"
                                  id="boximg-img2"
                                  htmlFor={imageSelected3 ? "image3" : "none"}
                                >
                                  <div className="rectangle-content">
                                    <div></div>
                                  </div>
                                  {imageSelected3 && (
                                    <img
                                      src={imageSelected3}
                                      className="w-100"
                                    />
                                  )}
                                </div>
                              </div>

                              <div
                                className="dropdown"
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                <button
                                  className="btn btn-link btn-help p-0 ml-1 mb-1"
                                  title="Medidas recomendadas"
                                  type="button"
                                  style={{ zIndex: "9999" }}
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <BiSolidHelpCircle
                                    className="icon-help-circled"
                                    style={{ fontSize: "18px" }}
                                  />
                                </button>
                                <div
                                  className="dropdown-menu p-0 text-center"
                                  aria-labelledby="dropdownMenuButton"
                                  style={{ width: "350px" }}
                                >
                                  <header className="py-1 px-2">
                                    <strong>Medidas recomendadas</strong>
                                  </header>
                                  <div className="px-2">
                                    Recomendamos usar uno de los siguientes tres
                                    formatos: <br />
                                    a) 1200 x 800 - horizontal.
                                    <br />
                                    b) 800 x 1200 - vertical.
                                    <br />
                                    c) 1200 x 1200 cuadrada.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <a
                          href="javascript:;"
                          className="btn btn-lg btn-default mr-1"
                          onClick={() => {
                            setMode("list");
                          }}
                        >
                          Volver
                        </a>
                        <input
                          className="btn btn-lg btn-info"
                          value="Guardar"
                          type="submit"
                          // type="button"
                        />
                      </div>
                    </div>
                  </footer>
                </form>
              </section>
            ) : null}
          </div>
        )}
        {/* <footer className="px-3 mt-4">
          <img src={logoFooter} alt="Logo" style={{ width: "140px" }} />
          <p
            style={{
              color: "rgba(0, 0, 0, .4)",
              marginLeft: "35px",
              margiTop: "5px",
            }}
          >
            <strong>{name_version_app}</strong>
            {number_version_app}
          </p>
        </footer>
      </div> */}

      <Modal
        status={modal}
        changeStatus={setModal}
        title="Archivo adjunto"
        // action={cleanInputs}
        width={"800px"}
        height={"300px"}
      >
        <Content>
          {/* Creacion del formulario para el registro de cada usuario o persona */}
          <div className="justify-content-center">
            <form>
              <div className="justify-content-center">
                <div className="row mb-3">
                  <img src={mediaSelected} />
                </div>
              </div>
              <div className="row justify-content-end">
                <input
                  className="btn btn-lg btn-info"
                  value="Cerrar"
                  name="submit"
                  id="submit"
                  type="button"
                  onClick={() => {
                    setModal(false);
                    setMediaSelected("");
                  }}
                />
              </div>
            </form>
          </div>
        </Content>
      </Modal>
    </Fragment>
  );
};

export default Support;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 50%;
    vertical-align: top;
    border-radius: 3px;
    margin: 0 auto;
  }

  // label {
  //   font-weight: 900;
  //   font-size: 18px;
  // }

  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;
