import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Avvvatars from "avvvatars-react";
import Modal from "../layout/Modal";
import Swal from "sweetalert2";
import styled from "styled-components";
import useAPI from "../../hooks/useAPI";
import dayjs from "dayjs";
import Spinner from "../plugins/Spinner";
import { BsWhatsapp, BsPlusSquareDotted, BsTrash3, BsPencilFill, BsPersonVcard, BsListStars, BsFillPersonCheckFill } from "react-icons/bs";
import { FaSortDown, FaSortUp, FaEye, FaSpinner, FaTags, FaShoppingBasket  } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { IoClose  } from "react-icons/io5";
import { IoMdFunnel } from "react-icons/io";
import { TiShoppingCart } from "react-icons/ti";

const ContactInfo = ({
  isDark,
  userLogout,
  classInfo,
  nameInitials,
  contactName,
  phoneContact,
  fieldsList,
  listNotes,
  isSavedChat,
  listsContact,
  allLists,
  responsibleContact,
  listTags,
  listUserChats,
  idContactSelected,
  setShowContactInfo,
  setClassInfo,
  setContactname,
  setContactNameSelected,
  setNameInitials,
  setListsContact,
  formPutPerson,
  error8,
  getNotes,
  listStages,
  getStages,
  orders
}) => {

  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [modalViewNote, setModalViewNote] = useState(false)
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [isDisableSelect, setIsDisableSelect] = useState(true);
  const [addNewSegment, setAddNewSegment] = useState(false);
  const [addNewStage, setAddNewStage] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [addNewNote, setAddNewNote] = useState(false);
  const [idNote, setIdNote] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [noteSelected, setNoteSelected] = useState([])
  const [mainData, setMainData] = useState(false);
  const [tagsSection, setTagsSection] = useState(false);
  const [ordersSection, setOrdersSection] = useState(false);
  const [responsibleView, setResponsibleView] = useState(false);
  const [segments, setSegments] = useState(false);
  const [condition, setCondition] = useState(false);
  const [listsSelected, setListsSelected] = useState([]);
  const [loadingGetNote, setLoadingGetNote] = useState(false);
  const [stagesSection, setStagesSection] = useState(false);
  const [stageValue, setStageValue] = useState(null);

  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI();

  const {watch,register, handleSubmit, formState: { errors }, reset} = useForm()

  const messageRef = useRef(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };

  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };
  
  const customNoOptionsMessage = () => "Sin opciones";

  //#--> Afrear usuario a listas
  const addUserToList = async () => {
    console.log("id contactto====>", idContactSelected);
    console.log("Listas asignadas====>", listsSelected);
     console.log("Que tiene listsContact cuando se guarda la lista", listsContact);
    setAddNewSegment(false);
    const values = {
      userId: idContactSelected,
      dataSegmentCheck: listsSelected,
    };
    console.log(values);
    console.log("values", values.userId);
    try {
      setLoading(true);
      const url = urlAPI_1 + "addUserToList";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      // setListsModal(false);
      setLoading(false);
      // navigate('/home/users');
      setIsDisableSelect(true);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Funcion para almacenar las listas asignadas al contacto a crear
  const handleChangeList = data => {
    var idLists = [];
    for(var list of data){idLists.push(list._id)}
    setListsSelected(idLists)
    setListsContact(data);
  };

  //Funcion que obtiene una nota
  const getNote = async (id) => {
    console.log("Ejecuutando getNote.....................");
    setLoadingGetNote(true)
    setModeEdit(true);
    setAddNewNote(true);
    setIdNote(id);
    try {
      const url = urlAPI_1 + "notes/" + phoneContact + "/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("response note..............", result);

      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.data.length === 0) {
        console.log("sin data");
        // setValidation(true);
        reset({ note: "" });
      } else {
        console.log("si hay data....");
        reset({ note: result.data[0].note });
        // setValidation(false);
      }
      setLoadingGetNote(false)

    } catch (error) {
      console.log(error);
    }
  };

  //Ver infromación de la nota seleccionada
  const viewNote = async (id) => {
    const filtNote = listNotes.filter(note => note.id === id)[0];
    setNoteSelected(filtNote)
    console.log('----Nota seleccionada---->', filtNote)
    setModalViewNote(true)
  }

  // Enviar nueva nota
  const postNote = async () => {
    const { note } = watch();
    console.log("creandoooooooooooooooo");
    var date = new Date();

    var object = {
      note: note,
      date: date,
      agent: userLogout,
    };

    console.log("objeto a enviar...=>", object);

    try {
      setLoading(true);
      const url = urlAPI_1 + "addNote/" + phoneContact;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(object)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("nota creada........", result);

      setLoading(false);
      reset({ note: "" });
      getNotes(phoneContact);
      setNewNote("");
      setAddNewNote(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Función para editar una nota
  const putNote = async () => {
    console.log("Editando nota..........");
    const { note } = watch();
    var date = new Date();

    var newObject = {
      note: note,
      date: date,
      agent: userLogout,
      id: idNote,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + `addNote/${phoneContact}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(newObject)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data Updated notas.........", result);

      setLoading(false);
      reset({ note: "" });
      setAddNewNote(false);
      getNotes(phoneContact);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      // navigate("/home/support");
    } catch (error) {
      console.log(error);
    }
  };

  //Función para eliminar una nota
  const deleteNote = async (id) => {
    console.log("eliminando nota.......");
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta nota?",
        text: "No podrás recuperarla.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_1 + `notes/${phoneContact}/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Data Updated notas.........", result);

          setLoading(false);
          getNotes(phoneContact);
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
          // navigate("/home/support");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Funcion para actualizar
  const putPerson = async () => {
    console.log("Actualizando persona...")
    const objectData = formPutPerson.watch();
    console.log(".................objectData...............", objectData)
    const id = objectData._id;
    var tags = []
    console.log(".................🏷listTags🏷...............", listTags)
    for(var tag of listTags){
      if (tag.tagActive) {
        tags.push(tag._id)
      }
    }
    objectData.tags = tags.join(',');
    objectData.responsible = selectedAgent.name;
    console.log(".................selectedAgent...............", selectedAgent);
    console.log(".................objectData...............", objectData);
    try {
      setLoading(true);
      const url = urlAPI_1 + `persons/${id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(objectData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log(".................result...............", result);
      setContactname(result.data.name);
      setContactNameSelected(result.data.name);
      console.log(".................listUserChats...............", listUserChats)
      console.log("..................result.data................", result.data)
      const filterArray = listUserChats.filter(obj => obj.phoneNumber.slice(-10) === result.data.phone.slice(-10))
      console.log("..................filterArray................", filterArray)
      console.log("...................fieldsList................", fieldsList)
      if(fieldsList.length > 0){
        // extraer los nombres de los campos del estado
        const namefieldsList = fieldsList.map(obj => obj.name)
        // Filtrar el segundo array para obtener solo las claves que coinciden con los nombres de los campos del primer array
        const camposCoincidentes = Object.keys(filterArray[0].userData).filter(key => namefieldsList.includes(key));
        
        // Asignar los nuevos valores a los campos coincidentes en userData
        camposCoincidentes.forEach(campo => {
          if (filterArray.length && filterArray[0].userData && filterArray[0].userData.hasOwnProperty(campo)) {
            filterArray[0].userData[campo] = result.data.aditionalFields[campo];
          }
        });
                
        
      } else {
        console.log("no hay campos nuevos para este contacto entonces no se actualizan");
      }
      console.log("...................filterArray[0].userData................", filterArray[0].userData)
      filterArray[0].userData.name = result.data.name;
      filterArray[0].userData.email = result.data.email;
      console.log("...................filterArray[1].userData................", filterArray[0].userData)
      filterArray[0].user = result.data.name;
      const initialWords = filterArray[0].user.split(" ");
      const firstWord = initialWords[0];
      const secondWord = initialWords[1];
      let iconName = "";
      if (secondWord === undefined) {
        iconName = firstWord[0];
      } else {
        iconName = firstWord[0] + secondWord;
      }
      setNameInitials(iconName);
      console.log("Data Updated", result);
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log("error in Put Person:", error);
      setLoading(false);
    }
  };

  // Funcion para agregar contacto a etapa
  const putStages = async () => {
    
    const findUser = stageValue.prospects.find(prospect => prospect._id == idContactSelected)
    if(!findUser) {
      try {
        const prospects = [
          ...stageValue.prospects.map(prospect => {
            if(typeof prospect === 'object') {
              return prospect._id
            }
          }), 
          idContactSelected
        ]
        const dataStage = {
          prospect: idContactSelected,
          data: {
            ...stageValue,
            prospects: prospects
          }
        }

        setLoading(true);
        const url = urlAPI_3 + `stagesAddProspect/${stageValue._id}`;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(dataStage)};
        await fetchWithAuth(url, options);
        await getStages();
        setAddNewStage(false);
        setIsDisableSelect(true);
        setLoading(false);
        Swal.fire({
          title: "¡Bien!",
          text: "Contacto agregado a la etapa " + stageValue.name,
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      return Swal.fire({
        title: "¡Atencíon!",
        text: "El contacto ya se encuentra en esta etapa, por lo tanto no se puede volver a guardar.",
        icon: "info",
        confirmButtonText: "Entendido",
      });
    }
    
  };
  const findProspectStage = () => {
    setStageValue(null);
    if(listStages && listStages.length) {
      listStages.forEach(v => {
        if(v.prospects.length){
          const find = v.prospects.find(f => f._id === idContactSelected)
          if(find) {
            setStageValue(v);
          }
        }
      })
    }
  }

  useEffect(() => {
    findProspectStage()
  }, [idContactSelected])

  return (
    <>
      {loading && (
        <div className="content-spinner">
          <Spinner statusLoading={statusLoading} />
        </div>
      )}
      <section className={`module-chat info ${classInfo && "active"}`} style={{ width: "27%" }}>
        <header className="message" style={{height:"7%"}}>
          <div className="d-flex">
            <div style={{ alignItems: "center", display: "flex" }}>
              <Botton
                type="button"
                onClick={() => {
                  setShowContactInfo(false)
                  setClassInfo(false);
                }}
              >
                <IoClose className="icon-cancel" style={{ fontSize: "1.5rem" }} />
              </Botton>
            </div>
            <div id="customer-name" className="ml-2 d-flex">
              <h1>Info. del contacto</h1>
            </div>
          </div>
        </header>

        <div ref={messageRef} className="user-information mp-2" style={{ height: "93%", overflow: "auto" }}>
          <ul className="chat">
            <li>
              <Icon>
                <Avvvatars value={nameInitials} />
              </Icon>
            </li>
            <li className="text-center">
              <h5>{contactName}</h5>
            </li>
            <li className="text-center">
              <p className="d-flex justify-content-center span-color">
                <BsWhatsapp className="mr-1" style={{ fontSize: "18px" }} />
                +{phoneContact}
              </p>
            </li>
          </ul>
          <div>
            <div className="main-data">
              <h5 className="pointer d-flex justify-content-between"
                onClick={() => {
                  setCondition(false);
                  setSegments(false);
                  setResponsibleView(false);
                  setTagsSection(false);
                  setOrdersSection(false);
                  setStagesSection(false);
                  setMainData(prevMainData => !prevMainData);
                }}
              >
                <div className="d-flex align-items-center">
                  <BsPersonVcard className="mr-1"/>
                  Datos principales
                </div>
                {mainData ?
                  <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                  <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                }
              </h5>
              {mainData && (
                <form onSubmit={formPutPerson.handleSubmit(putPerson)}>
                  <Div className="justify-content-center pt-2">
                    <div className="form-group mr-0 col-6 span-color">
                      <label htmlFor="email">Correo:</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        // value={emailContact}
                        {...formPutPerson.register("email", {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Formato incorrecto",
                          },
                        })}
                      />
                      {error8.email && <Alert className="w-100">* {error8.email.message}</Alert>}
                    </div>
                    <div className="form-group mr-0 col-6 span-color">
                      <label htmlFor="name">Nombre:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        {...formPutPerson.register("name", {
                          required: {
                            value: true,
                            message: "El nombre es requerido.",
                          },
                        })}
                      />
                      {error8.name && <Alert>* {error8.name.message}</Alert>}
                    </div>
                    
                    {fieldsList && fieldsList.map((field, k) => (
                      <div key={k} className="form-group mr-0 col-6 span-color">
                        <label htmlFor={field.name}>{field.name}</label>
                        {field.type === "textarea" ? (
                          <textarea
                            name={field.name}
                            className="form-control"
                            id={field.name}
                            {...formPutPerson.register(field.name)}
                          ></textarea>
                        ) : (
                          <input
                            type={field.type}
                            className="form-control"
                            name={field.name}
                            id={field.name}
                            {...formPutPerson.register(field.name)}
                          />
                        )}
                      </div>
                    ))}
                  </Div>
                  <div className="text-right pr-2">
                    <input
                      className="btn btn-lg btn-info"
                      style={{ fontSize: "0.89em" }}
                      value="Actualizar"
                      name="submit"
                      id="submit"
                      type="submit"
                    />
                  </div>
                </form>
              )}
            </div>

            <div className="condition">
              <h5
                className="pointer d-flex justify-content-between"
                onClick={() => {
                  setMainData(false);
                  setSegments(false);
                  setResponsibleView(false);
                  setTagsSection(false);
                  setOrdersSection(false);
                  setStagesSection(false);
                  setCondition(prevCondition => !prevCondition);
                }}
              >
                <div className="d-flex align-items-center">
                  <CgNotes className="mr-1"/>
                  Notas
                </div>
                {condition ?
                  <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                  <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                }
              </h5>
              {condition && (
                <>
                  <div className="mt-3" style={{borderBottom: "2px solid rgb(229 231 235 / 51%)",}}>
                    <ul className="list-notes" style={{ marginLeft: "10px" }}>
                      {listNotes &&
                        listNotes.map((note, k) => (
                          <li key={k} className="py-1 d-flex justify-content-between pointer span-color">
                            <div>- {note.note.length >= 35
                                ? note.note.substr(0, 35) + "..."
                                : note.note
                            }</div>
                            <div className="d-flex">
                              <FaEye className="icon-pencil mr-2" title="Ver" style={{ fontSize: "15px" }} onClick={() => viewNote(note.id)}/>
                              <BsPencilFill className="icon-pencil mr-2" title="Editar" style={{ fontSize: "15px" }} onClick={() => getNote(note.id)}/>
                              <BsTrash3 className="icon-trash-empty" title="Eliminar" style={{ fontSize: "15px" }} onClick={() => deleteNote(note.id)}/>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {addNewNote ? (
                    <form>
                      <Div className="col-md-12">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable span-color">
                          <label htmlFor="phone">{`${modeEdit ? 'Editar' : 'Agregar'} nota:`}</label>
                          <div className="su-control-group">
                            {loadingGetNote ? 
                              <div className="loading-send-template d-flex justify-content-center align-items-center">
                                <span style={{ padding: "12px" }}>
                                    <FaSpinner className="fa-spin" style={{ fontSize: "25px" }} />
                                </span>
                            </div>
                            :
                              <textarea
                                name="note"
                                className="form-control"
                                id="note"
                                {...register("note")}
                                onChange={(e) => setNewNote(e.target.value)}
                              ></textarea>
                            }
                          </div>
                        </div>
                      </Div>
                      <div className="text-right pr-2">
                        <a
                          style={{ fontSize: "0.89em" }}
                          href="javascript:;"
                          className="btn btn-lg btn-default mr-1"
                          onClick={() => {
                            setModeEdit((prevMessage) => { return false});
                            setAddNewNote((prevMessage) => { return false});
                          }}
                        >
                          Cancelar
                        </a>
                        <input
                          style={{ fontSize: "0.89em" }}
                          className="btn btn-lg btn-info"
                          value={modeEdit ? "Actualizar" : "Guardar"}
                          name="submit"
                          id="submit"
                          type="button"
                          onClick={() => modeEdit ? putNote() : postNote()}
                        />
                      </div>
                    </form>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-link btn-link-blue btn-toggle btn-personalize mb-1 d-flex"
                        onClick={() => {
                          setAddNewNote(true);
                          setModeEdit(false)
                          reset({ note: "" });
                        }}
                      >
                        <BsPlusSquareDotted className="mr-1" style={{ fontSize: "16px" }}/>
                        Agregar nota
                      </button>
                    </div>
                  )}

                </>
              )}
            </div>

            {isSavedChat && (
              <div className="segments">
                <h5
                  className="pointer d-flex justify-content-between"
                  onClick={() => {
                    setMainData(false);
                    setCondition(false);
                    setResponsibleView(false);
                    setTagsSection(false);
                    setOrdersSection(false);
                    setStagesSection(false);
                    setSegments(prevSegments => !prevSegments)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <BsListStars className="mr-1"/>
                    Listas
                  </div>
                  {segments ?
                    <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                  }
                </h5>
                {segments && (
                  <form>
                    <Div className="col-md-12">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <div className="su-control-group">
                          <Select
                            styles={isDark == 'true' ? customStylesDark : customStyles}
                            className="form-control basic-multi-select"
                            value={listsContact}
                            isMulti
                            placeholder="Seleccione..."
                            name="tags"
                            onChange={handleChangeList}
                            options={allLists}
                            classNamePrefix="select"
                            noOptionsMessage={customNoOptionsMessage}
                            getOptionLabel={(option) => (
                              <div className="option d-flex options-list-multi" style={{fontSize: "15px"}}>
                                {option.name}
                              </div>
                            )}
                            getOptionValue={(option) => option._id}
                            isDisabled={isDisableSelect}
                          />
                        </div>
                      </div>
                    </Div>
                    {addNewSegment ? (
                      <div className="text-right pr-2 mt-3">
                        <a
                          href="javascript:;"
                          style={{ fontSize: "0.89em" }}
                          className="btn btn-lg btn-default mr-1"
                          onClick={() => {
                            setAddNewSegment((prevMessage) => { return false});
                            setIsDisableSelect((prevMessage) => { return true});
                          }}
                        >
                          Cancelar
                        </a>
                        <input
                          style={{ fontSize: "0.89em" }}
                          className="btn btn-lg btn-info"
                          value="Guardar"
                          name="submit"
                          id="submit"
                          type="button"
                          onClick={() => addUserToList()}
                        />
                      </div>
                    ):(
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-link btn-link-blue btn-toggle btn-personalize mb-1 d-flex"
                          onClick={() => {
                            setAddNewSegment(true);
                            setIsDisableSelect(false);
                          }}
                        >
                          {/* <BsPlusSquareDotted className="mr-1" style={{ fontSize: "16px" }}/> */}
                          <BsPencilFill className="mr-1" style={{ fontSize: "16px" }}/>
                          Agregar o eliminar lista
                        </button>
                      </div>
                    )}
                  </form>
                )}
              </div>
            )}

            {/* {isSavedChat &&  */}
            {responsibleContact && (
              <div className="condition">
                <h5
                  className="pointer d-flex justify-content-between"
                  onClick={() => {
                    setMainData(false);
                    setSegments(false);
                    setCondition(false);
                    setTagsSection(false);
                    setOrdersSection(false);
                    setStagesSection(false);
                    setResponsibleView(prevResponsibleView => !prevResponsibleView)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <BsFillPersonCheckFill className="mr-1"/>
                    Responsable
                  </div>
                  {responsibleView ?
                    <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                  }
                </h5>
                {responsibleView &&
                  <div className="mt-3 mb-2 text-center">{responsibleContact}</div>
                }
              </div>
            )}

            {listTags && (
              <div className="condition">
                <h5
                  className="pointer d-flex justify-content-between"
                  onClick={() => {
                    setMainData(false);
                    setSegments(false);
                    setCondition(false);
                    setResponsibleView(false);
                    setOrdersSection(false);
                    setStagesSection(false);
                    setTagsSection(prevTagsSection => !prevTagsSection)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <FaTags className="mr-1"/>
                    Etiquetas
                  </div>
                  {tagsSection ?
                    <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                  }
                </h5>
                {tagsSection &&
                  <div className="mt-3 mb-2 text-center">
                    <ul className="justify-content-center" style={{display:"grid"}}>
                      {listTags.some(tag => tag.tagActive) ? (
                        listTags.map((tag, t) => (
                          tag.tagActive &&
                          <li key={t} className="m-1 px-2 py-1" style={{backgroundColor: tag.color, color: "#fff", borderRadius: "6px"}}>
                            <span style={{fontWeight: "600"}}>{tag.title}</span>
                          </li>
                        ))
                      ) : (
                        <li className="span-color">Sin etiquetas</li>
                      )}
                    </ul>

                  </div>
                }
              </div>
            )}
            
            {orders && orders.length > 0 && (
              <div className="condition">
                <h5
                  className="pointer d-flex justify-content-between"
                  onClick={() => {
                    setMainData(false);
                    setSegments(false);
                    setCondition(false);
                    setTagsSection(false);
                    setResponsibleView(false);
                    setStagesSection(false);
                    setOrdersSection(prevOrdersSection => !prevOrdersSection)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <TiShoppingCart className="mr-1"/>
                    Pedidos
                  </div>
                  {ordersSection ?
                    <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                  }
                </h5>
                {ordersSection &&
                  <div className="mt-3 mb-2 text-center">
                    <ul className="justify-content-center" style={{display:"grid"}}>
                      {
                        orders.slice(0,8).map((order) => (
                          <li key={order._id} className="m-1 px-2 py-1">
                            <Link to={`/home/order/${order._id}`} className='span-color d-flex align-items-center px-1'>
                              <FaShoppingBasket className="mr-1"/> Pedido N° {order.id} por {order.total}
                            </Link>
                          </li>
                        ))
                      }
                      {
                        orders.length > 8 && (
                          <li className='mx-2 px-2' style={{fontWeight: "700"}}>
                            <Link to={`/home/orders`} className='text-info MuiButton-textPrimary'>
                              Ver más...
                            </Link>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                }
              </div>
            )}

            {listStages && (
              <div className="condition">
                <h5
                  className="pointer d-flex justify-content-between"
                  onClick={() => {
                    setMainData(false);
                    setSegments(false);
                    setCondition(false);
                    setResponsibleView(false);
                    setTagsSection(false);
                    setOrdersSection(false);
                    setStagesSection(prevStagesSection => !prevStagesSection);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <IoMdFunnel className="mr-1"/>
                    Etapas
                  </div>
                  {stagesSection ?
                    <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                  }
                </h5>
                {stagesSection &&
                  <form>
                    <div className="col-md-12">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <div className="su-control-group">
                          <Select
                            styles={isDark == 'true' ? customStylesDark : customStyles}
                            className="form-control basic-multi-select"
                            placeholder="Seleccione..."
                            value={stageValue}
                            name="stages"
                            onChange={(stage) => {
                              setStageValue(stage)
                            }}
                            options={listStages}
                            classNamePrefix="select"
                            noOptionsMessage={customNoOptionsMessage}
                            getOptionLabel={(option) => (
                              <div className="option d-flex options-list-multi" style={{fontSize: "15px"}}>
                                {option.name}
                              </div>
                            )}
                            getOptionValue={(option) => option._id}
                            isDisabled={isDisableSelect}
                          />
                        </div>
                      </div>
                    </div>
                    {addNewStage ? (
                      <div className="text-right pr-2 mt-3">
                        <a
                          href="javascript:;"
                          style={{ fontSize: "0.89em" }}
                          className="btn btn-lg btn-default mr-1"
                          onClick={() => {
                            setAddNewStage(() => { return false});
                            setIsDisableSelect(() => { return true});
                          }}
                        >
                          Cancelar
                        </a>
                        <input
                          style={{ fontSize: "0.89em" }}
                          className="btn btn-lg btn-info"
                          value="Guardar"
                          name="submit"
                          id="submit"
                          type="button"
                          onClick={() => {putStages()}}
                          disabled={!stageValue}
                        />
                      </div>
                    ):(
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-link btn-link-blue btn-toggle btn-personalize mb-1 d-flex"
                          onClick={() => {
                            setAddNewStage(true);
                            setIsDisableSelect(false);
                          }}
                        >
                          <BsPencilFill className="mr-1" style={{ fontSize: "16px" }}/>
                          {stageValue ? "Editar etapa" : "Agregar etapa"}
                        </button>
                      </div>
                    )}
                  </form>
                }
              </div>
            )}

          </div>
        </div>
      </section>

      {/* Modal para mostrar la información de las notas */}
      <Modal
        status={modalViewNote}
        changeStatus={setModalViewNote}
        title= "Información de la nota"
        width={"600px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <div className="su-control mt-2 text-center">
                <ul>
                  <li>
                    <h4>Creada por:</h4>
                    <p>{noteSelected.agent}</p>
                  </li>
                  <li>
                    <h4>Creada el:</h4>
                    <p>
                    {dayjs(noteSelected.date)
                      .locale("es")
                      .format("DD MMMM YYYY - HH:mm a")}</p>
                  </li>
                  <li>
                    <h4>Contenido:</h4>
                    <p>{noteSelected.note}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalViewNote((prevMessage) => { return false});
                }}
              >
                Cerrar
              </a>
            </div>
          </form>


        </Content>
      </Modal>
    </>
  );
};

export default ContactInfo;

const Botton = styled.button`
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    color: #000000;
    opacity: 0.7;
  }
`;
const Icon = styled.div`
  margin-bottom: 20px;

  div {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 0 auto;
  }

  p {
    font-size: 40px;
    font-weight: 600;
  }
`;
const Div = styled.div`
  label {
    font-size: 14px;
  }

  input,
  textarea {
    font-size: 1em;
  }
`;
const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;