import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../plugins/Spinner";
import Header from "../layout/Header";
import Swal from "sweetalert2";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import useAPI from "../../hooks/useAPI";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { FaListUl, FaLockOpen, FaMinusCircle, FaAngleLeft } from "react-icons/fa";
import { BiSolidHelpCircle } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";

const Support = () => {
  const listDepartment = [
    {
      id: 1,
      content: "Información general",
    },
    {
      id: 2,
      content: "Dudas o inquietudes",
    },
    {
      id: 3,
      content: "Configuraciones generales",
    },
    {
      id: 4,
      content: "Reporte de fallas o errores",
    },
    {
      id: 5,
      content: "Asistencia técnica",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);

  const [mode, setMode] = useState("list");

  const [allTickets, setAllTickets] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [navegationView, setNavegationView] = useState("all");
  const [filtOpen, setFiltOpen] = useState([]);
  const [filtClosed, setFiltClosed] = useState([]);
  const [filtAll, setFiltAll] = useState([]);
  const [validationFilt, setValidationFilt] = useState(false);
  const [nameFilt, setNameFilt] = useState("");

  const [showInfo, setShowInfo] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const [showInfo3, setShowInfo3] = useState(false);

  const [imageSelected1, setImageSelected1] = useState("");
  const [imageSelected2, setImageSelected2] = useState("");
  const [imageSelected3, setImageSelected3] = useState("");

  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI();
  const token = localStorage.getItem("USER_TOKEN");

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      department: "",
      affair: "",
      question: "",
    },
  });

  useEffect(() => {
    setDepartmentList(listDepartment);
    getAllTickets();
    getTickets();
  }, []);

  // Listar tickets
  const getAllTickets = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "allTickets";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA de todos los tickets ==========", result.data);
      setAllTickets(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTickets = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "tickets";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA tickets ==========", result);

      setLoading(false);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      } else if (!result.data) {
        setValidation(true);
        setTicketList([]);
      } else {
        setTicketList(result.data);
        setFiltOpen(result.data);
        setFiltClosed(result.data);
        setFiltAll(result.data);
        setValidation(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postTicket = async () => {
    const { department, affair, question } = watch();

    var objMedia = {};
    if (imageSelected1) {
      objMedia = { image: imageSelected1 };

      if (imageSelected2) {
        objMedia = {
          image: imageSelected1,
          image2: imageSelected2,
        };
      }
      if (imageSelected3) {
        objMedia = {
          image: imageSelected1,
          image2: imageSelected2,
          image3: imageSelected3,
        };
      }
    }

    if (allTickets) {
      var fil = allTickets[allTickets.length - 1];
      var consecutive = "";
      if (fil) {
        consecutive = fil.consecutiveId + 1;
      } else {
        consecutive = 1;
      }
    } else {
      consecutive = 1;
    }

    const object = {
      consecutiveId: consecutive,
      department,
      affair,
      question: [
        {
          content: question,
          media: objMedia,
        },
      ],
      priority: "Sin asignar",
      status: "Abierto",
    };

    console.log("OBJETO a GUARDAR", object);
    try {
      setLoading(true);
      const url = urlAPI_3 + "tickets";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(object)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Ticket creado........", result);

      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setLoading(false);
      getTickets();
      setMode("list");
      reset();
      setImageSelected1("");
      setImageSelected2("");
      setImageSelected3("");
    } catch (error) {
      console.log(error);
    }
  };

  // FILTRAR ABIERTOS
  const onOpen = () => {
    const resultFilter = filtOpen.filter((element) => {
      if (element.status === "Abierto") {
        return element;
      }
    });
    if (resultFilter.length === 0) {
      setValidationFilt(true);
      setNameFilt("Abiertos");
    } else {
      setValidationFilt(false);
    }
    setTicketList(resultFilter);
    setNavegationView("open");
  };

  // FILTRAR CERRADOS
  const onClosed = () => {
    const resultFilter1 = filtClosed.filter((element) => {
      if (element.status === "Solucionado") {
        return element;
      }
    });

    if (resultFilter1.length === 0) {
      setValidationFilt(true);
      setNameFilt("Cerrados");
    } else {
      setValidationFilt(false);
    }
    setTicketList(resultFilter1);
    setNavegationView("closed");
  };

  // FILTRAR POR TODOS
  const onAll = async () => {
    setTicketList(filtAll);
    setNavegationView("all");
    setValidationFilt(false);
  };

  //Convertit imagen 1 en base64
  const onImage1 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage1Base64(file);
    console.log("Imagen 1========> ", base64);
    setImageSelected1(base64);
  };
  const convertImage1Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 2 en base64
  const onImage2 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage2Base64(file);
    console.log("Imagen 2========> ", base64);
    setImageSelected2(base64);
  };
  const convertImage2Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Convertit imagen 3 en base64
  const onImage3 = async (e) => {
    var file = e.target.files[0];
    var base64 = await convertImage3Base64(file);
    console.log("Imagen 3========> ", base64);
    setImageSelected3(base64);
  };
  const convertImage3Base64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {mode === "list" && (
        <>
          <div className="col-xs-9 heading">
            <div>
              <h1>Tickets</h1>
              <input
                type="button"
                className="btn btn-default"
                value="Crear ticket"
                onClick={() => {
                  setMode("create");
                  reset();
                  setImageSelected1("");
                  setImageSelected2("");
                  setImageSelected3("");
                }}
              />
            </div>
            <Header />
          </div>

          <header>
            <input
              type="hidden"
              id="idStore"
              size="40"
              value="1399"
              name="store"
            />
            <button
              className={`btn-filter btn btn-link btn-link-all ${
                navegationView === "all" ? "active" : null
              }`}
              onClick={onAll}
            >
              <FaListUl style={{ fontSize: "23px", verticalAlign: "middle" }}/>{" "}
              <span style={{ verticalAlign: "middle", lineHeight: "23px" }}>Todos</span>
            </button>
            <a
              className={`btn-filter btn btn-link ${
                navegationView === "open" ? "active" : null
              }`}
              onClick={onOpen}
            >
              <FaLockOpen style={{ fontSize: "23px", verticalAlign: "middle" }}/>{" "}
              <span style={{ verticalAlign: "middle", lineHeight: "23px" }}>Abiertos</span>
            </a>
            <a
              className={`btn-filter btn btn-link ${
                navegationView === "closed" ? "active" : null
              }`}
              onClick={onClosed}
            >
              <FaMinusCircle style={{ fontSize: "23px", verticalAlign: "middle" }}/>{" "}
              <span style={{ verticalAlign: "middle", lineHeight: "23px" }}>Cerrados</span>
            </a>
          </header>

          <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflowY: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr key="">
                  <th># Ticket</th>
                  <th>Departamento</th>
                  <th>Asunto</th>
                  <th>Fecha de solicitud</th>
                  <th>Hora de solicitud</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody style={{ width: "1170px" }}>
                {ticketList &&
                  ticketList.map((ticket, t) => (
                    <tr key={t}>
                      <td
                        onClick={() => {
                          // getTicket(ticket._id);
                          navigate(`/home/supportDetail/${ticket._id}`);
                        }}
                      >
                        {ticket.consecutiveId}
                      </td>
                      <td>{ticket.department}</td>
                      <td>{ticket.affair}</td>
                      <td>
                        {dayjs(ticket.created)
                          .locale("es")
                          .format("DD MMMM YYYY")}
                      </td>
                      <td>
                        {dayjs(ticket.created).locale("es").format("hh:mm:ss")}
                      </td>
                      <td>{ticket.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {validation && (
              <div className="text-center mt-3">
                <div className="empty">
                  <BiSolidHelpCircle
                    className="icon-help-circled"
                    style={{ fontSize: "55px" }}
                  />
                  <h1>No tienes tickets</h1>
                  <input
                    type="button"
                    className="btn btn-default"
                    value="Crear ticket"
                    onClick={() => {
                      setMode("create");
                    }}
                  />
                </div>
              </div>
            )}
            {validationFilt && (
              <div className="text-center mt-3">
                <div className="empty">
                  <h1>No tienes tickets {nameFilt}</h1>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {mode === "create" && (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-centers"
                onClick={() => {
                  setMode("list");
                }}
              >
                <FaAngleLeft /> Tickets
              </button>
            </div>
            <Header />
          </div>

          <section className="module-create-user" style={{ width: "100%" }}>
            <header className="message pb-2">
              <div id="tittleFormProduct">
                <h1>Crear solicitud</h1>
                <p>
                  Por favor, diligencia el siguiente formulario y proporciónenos
                  los datos más precisos posible para que podamos atender su
                  solicitud rápidamente
                </p>
              </div>
            </header>

            <form onSubmit={handleSubmit(postTicket)}>
              <div className="module-section" id="product-basic">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                          <label htmlFor="select">Departamento: </label>
                          <select
                            name="department"
                            id="department"
                            className="form-control"
                            {...register("department", {
                              required: {
                                value: true,
                                message: "El departamento es obligatorio.",
                              },
                            })}
                          >
                            <option value="" key="0" disabled selected>
                              Seleccione...
                            </option>
                            {departmentList &&
                              departmentList.map((dep, d) => (
                                <option value={dep.content} key={d}>
                                  {dep.content}
                                </option>
                              ))}
                          </select>
                          {errors.department && (
                            <Alert>* {errors.department.message}</Alert>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                          <label>Asunto: </label>
                          <div className="su-control-group">
                            <input
                              className="form-control"
                              name="affair"
                              id="affair"
                              aria-readonly
                              type="text"
                              {...register("affair", {
                                required: {
                                  value: true,
                                  message: "El asunto es obligatorio.",
                                },
                              })}
                            />
                          </div>
                          {errors.affair && (
                            <Alert>* {errors.affair.message}</Alert>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                          <label>Pregunta: </label>
                          <div className="su-control-group">
                            <textarea
                              className="form-control"
                              name="question"
                              id="question"
                              aria-readonly
                              style={{ height: "205px" }}
                              {...register("question", {
                                required: {
                                  value: true,
                                  message: "La pregunta es obligatoria.",
                                },
                              })}
                            ></textarea>
                            {errors.question && (
                              <Alert>* {errors.question.message}</Alert>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="su-control">
                          <label>Imagenes adjuntas: </label>{" "}
                          <small>adjunta máximo 3 imagenes</small>
                        </div>
                        <div className="su-product-gallery d-flex">
                          <div
                            className={
                              imageSelected1
                                ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                : "su-control su-widget-image small uncut image_url_supp mr-1"
                            }
                            id="image2_url"
                            data-key="image2_url"
                          >
                            <label htmlFor="image1">
                              <span>Imagen</span>
                            </label>
                            {imageSelected1 && (
                              <RiCloseCircleFill
                                className="close icon-cancel-circled mr-1"
                                onClick={() => setImageSelected1("")}
                                style={{
                                  position: "absolute",
                                  zIndex: "99999",
                                  right: "0",
                                  fontSize: "27px",
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="image1"
                              name="image1"
                              accept="image/gif,image/jpeg,image/jpg,image/png"
                              {...register("image")}
                              onChange={(e) => onImage1(e)}
                            />

                            <div
                              className="rectangle-box"
                              id="boximg-img2"
                              htmlFor={imageSelected1 ? "image1" : "none"}
                            >
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              {imageSelected1 && (
                                <img src={imageSelected1} className="w-100" />
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              imageSelected2
                                ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                : "su-control su-widget-image small uncut image_url_supp mr-1"
                            }
                            id="image2_url"
                            data-key="image2_url"
                          >
                            <label htmlFor="image2">
                              <span>Imagen</span>
                            </label>
                            {imageSelected2 && (
                              <RiCloseCircleFill
                                className="close icon-cancel-circled mr-1"
                                onClick={() => setImageSelected2("")}
                                style={{
                                  position: "absolute",
                                  zIndex: "99999",
                                  right: "0",
                                  fontSize: "27px",
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="image2"
                              name="image2"
                              accept="image/gif,image/jpeg,image/jpg,image/png"
                              onChange={(e) => onImage2(e)}
                            />

                            <div
                              className="rectangle-box"
                              id="boximg-img2"
                              htmlFor={imageSelected2 ? "image2" : "none"}
                            >
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              {imageSelected2 && (
                                <img src={imageSelected2} className="w-100" />
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              imageSelected3
                                ? "su-control su-widget-image small uncut image_url_supp mr-1 active"
                                : "su-control su-widget-image small uncut image_url_supp mr-1"
                            }
                            id="image2_url"
                            data-key="image2_url"
                          >
                            <label htmlFor="image3">
                              <span>Imagen</span>
                            </label>
                            {imageSelected3 && (
                              <RiCloseCircleFill
                                className="close icon-cancel-circled mr-1"
                                onClick={() => setImageSelected3("")}
                                style={{
                                  position: "absolute",
                                  zIndex: "99999",
                                  right: "0",
                                  fontSize: "27px",
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="image3"
                              name="image3"
                              accept="image/gif,image/jpeg,image/jpg,image/png"
                              onChange={(e) => onImage3(e)}
                            />

                            <div
                              className="rectangle-box"
                              id="boximg-img2"
                              htmlFor={imageSelected3 ? "image3" : "none"}
                            >
                              <div className="rectangle-content">
                                <div></div>
                              </div>
                              {imageSelected3 && (
                                <img src={imageSelected3} className="w-100" />
                              )}
                            </div>
                          </div>

                          <div
                            className="dropdown"
                            style={{ display: "flex", alignItems: "end" }}
                          >
                            <button
                              className="btn btn-link btn-help p-0 ml-1 mb-1"
                              title="Medidas recomendadas"
                              type="button"
                              style={{ zIndex: "999" }}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <BiSolidHelpCircle style={{ fontSize: "18px" }} />
                            </button>
                            <div
                              className="dropdown-menu p-0 text-center"
                              aria-labelledby="dropdownMenuButton"
                              style={{ width: "350px" }}
                            >
                              <header className="py-1 px-2">
                                <strong>Medidas recomendadas</strong>
                              </header>
                              <div className="px-2">
                                Recomendamos usar uno de los siguientes tres
                                formatos: <br />
                                a) 1200 x 800 - horizontal.
                                <br />
                                b) 800 x 1200 - vertical.
                                <br />
                                c) 1200 x 1200 cuadrada.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="mx-0">
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6 right">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => {
                        setMode("list");
                      }}
                    >
                      Volver
                    </a>
                    <input
                      className="btn btn-lg btn-info"
                      value="Aceptar"
                      type="submit"
                      // type="button"
                    />
                  </div>
                </div>
              </footer>
            </form>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default Support;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
`;
